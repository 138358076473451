import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'betconsole',
    loadChildren: () => import('./betconsole/betconsole.module').then( m => m.BetconsolePageModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./profile/profile.module').then( m => m.ProfilePageModule)
  },
  {
    path: 'history',
    loadChildren: () => import('./history/history.module').then( m => m.HistoryPageModule)
  },
  {
    path: 'deposit',
    loadChildren: () => import('./deposit/deposit.module').then( m => m.DepositPageModule)
  },
  {
    path: 'withdraw',
    loadChildren: () => import('./withdraw/withdraw.module').then( m => m.WithdrawPageModule)
  },
  {
    path: 'events',
    loadChildren: () => import('./events/events.module').then( m => m.EventsPageModule)
  },
  {
    path: 'updateprofile',
    loadChildren: () => import('./updateprofile/updateprofile.module').then( m => m.UpdateprofilePageModule)
  },
  {
    path: 'updatecontact',
    loadChildren: () => import('./updatecontact/updatecontact.module').then( m => m.UpdatecontactPageModule)
  },
  {
    path: 'updatepassword',
    loadChildren: () => import('./updatepassword/updatepassword.module').then( m => m.UpdatepasswordPageModule)
  },
  {
    path: 'updatesecurityquestion',
    loadChildren: () => import('./updatesecurityquestion/updatesecurityquestion.module').then( m => m.UpdatesecurityquestionPageModule)
  },
  {
    path: 'membership',
    loadChildren: () => import('./membership/membership.module').then( m => m.MembershipPageModule)
  },
  {
    path: 'forgotpassword',
    loadChildren: () => import('./forgotpassword/forgotpassword.module').then( m => m.ForgotpasswordPageModule)
  },
  {
    path: 'terms',
    loadChildren: () => import('./terms/terms.module').then( m => m.TermsPageModule)
  },
  {
    path: 'privacy',
    loadChildren: () => import('./privacy/privacy.module').then( m => m.PrivacyPageModule)
  },
  {
    path: 'rules',
    loadChildren: () => import('./rules/rules.module').then( m => m.RulesPageModule)
  },
  {
    path: 'agent',
    loadChildren: () => import('./agent/agent.module').then( m => m.AgentPageModule)
  },
  {
    path: 'kicked',
    loadChildren: () => import('./kicked/kicked.module').then( m => m.KickedPageModule)
  },
  {
    path: 'presignup',
    loadChildren: () => import('./presignup/presignup.module').then( m => m.PresignupPageModule)
  },
  {
    path: 'comhistory',
    loadChildren: () => import('./comhistory/comhistory.module').then( m => m.ComhistoryPageModule)
  },
  {
    path: 'downlineagent',
    loadChildren: () => import('./downlineagent/downlineagent.module').then( m => m.DownlineagentPageModule)
  },
  {
    path: 'downlineplayer',
    loadChildren: () => import('./downlineplayer/downlineplayer.module').then( m => m.DownlineplayerPageModule)
  },
  {
    path: 'approvalrequest',
    loadChildren: () => import('./approvalrequest/approvalrequest.module').then( m => m.ApprovalrequestPageModule)
  },
  {
    path: 'pasaload',
    loadChildren: () => import('./pasaload/pasaload.module').then( m => m.PasaloadPageModule)
  },
  {
    path: 'bank',
    loadChildren: () => import('./bank/bank.module').then( m => m.BankPageModule)
  },
  {
    path: 'add-bank',
    loadChildren: () => import('./add-bank/add-bank.module').then( m => m.AddBankPageModule)
  },
  {
    path: 'subagent',
    loadChildren: () => import('./subagent/subagent.module').then( m => m.SubagentPageModule)
  },
  {
    path: 'loginfailed',
    loadChildren: () => import('./loginfailed/loginfailed.module').then( m => m.LoginfailedPageModule)
  },
  {
    path: 'message',
    loadChildren: () => import('./message/message.module').then( m => m.MessagePageModule)
  },
  {
    path: 'esabongevents',
    loadChildren: () => import('./esabongevents/esabongevents.module').then( m => m.EsabongeventsPageModule)
  },
  {
    path: 'esabongdeclarator',
    loadChildren: () => import('./esabongdeclarator/esabongdeclarator.module').then( m => m.EsabongdeclaratorPageModule)
  },
  {
    path: 'tokentracer',
    loadChildren: () => import('./tokentracer/tokentracer.module').then( m => m.TokentracerPageModule)
  },
  {
    path: 'quickjoin',
    loadChildren: () => import('./quickjoin/quickjoin.module').then( m => m.QuickjoinPageModule)
  },
  {
    path: 'authorize',
    loadChildren: () => import('./authorize/authorize.module').then( m => m.AuthorizePageModule)
  },
  {
    path: 'timeout',
    loadChildren: () => import('./timeout/timeout.module').then( m => m.TimeoutPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
