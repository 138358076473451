import { Router } from '@angular/router';
import { Component } from '@angular/core';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  width: any;

  isAgent = false;

  constructor(private router: Router) {}

  //@HostListener('window:resize', ['$event'])

  /* onResize(event) {
		//this.width = event.target.innerWidth;
	} */

  onLogout(){
    localStorage.setItem('token','');
    this.router.navigate(['/home'])
    .then(() => {
      window.location.reload();
    });
  }

}
